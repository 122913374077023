<template>
  <b-card>
    <div class="custom-container">
      <h2>Administrador de video</h2>
      <div class="mt-4">
        <b-tabs content-class="mt-3" pills justified>
          <!-- seccion soporte -->
          <b-tab active>
            <template #title>
              <div
                class="d-flex justify-content-center align-items-center text-white-50"
                style="gap: 10px"
              >
                <i class="fas fa-circle-info" style="font-size: 1.2rem"></i>
                <h3 style="color: inherit">Soporte</h3>
              </div>
            </template>
            <Soporte_component />
          </b-tab>
          <!-- seccion academia -->
          <b-tab>
            <template #title>
              <div
                class="d-flex justify-content-center align-items-center text-white-50"
                style="gap: 10px"
              >
                <i class="fas fa-graduation-cap" style="font-size: 1.2rem"></i>
                <h3 style="color: inherit">Academia</h3>
              </div>
            </template>
            <Academia_component />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-card>
</template>

<script>
//components
import Soporte_component from "./Soporte_component.vue";
import Academia_component from "./Academia_component.vue";

export default {
  name: "videoManager",
  components: {
    Soporte_component,
    Academia_component,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
